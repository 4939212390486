<template>
  <b-row>
    <b-col cols="12" md="9" xl="8">
      <b-form ref="form" @submit.prevent>
        <b-row>
          <b-col class="m-auto">
            <b-card>
              <section>
                <b-row>
                  <b-col>
                    <!-- Libelle -->
                    <b-col class="m-auto">
                      <b-form-group label="" label-for="customer-name">
                        <label>
                          Libellé de l'inventaire<span class="p-0 text-danger h6">*</span>
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="libelle"
                          rules="required"
                        >
                          <b-form-input
                            id="customer-name"
                            v-model="libelle"
                            trim
                            :state="errors.length > 0 ? false : null"
                            @input="validateLibelle"
                            placeholder="Emprunt de rénovation"
                          />
                          <small
                            :class="valideLibelle ? 'block' : 'none'"
                            class="text-danger"
                          >
                            Vous devez renseigner le libellé de la depense
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- date inventaire -->
                    <b-col lg="12" class="m-auto">
                      <b-form-group>
                        <label>
                          Date de l'inventaire
                          <span class="p-0 text-danger h6">*</span>
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="date"
                            class="form-control"
                            @input="validateDate"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small
                            :class="valideDate ? 'block' : 'none'"
                            class="text-danger"
                          >
                            Vous devez renseigner la date de l'inventaire'
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- agence -->
                    <b-col lg="12" class="m-auto">
                      <b-form-group label="" label-for="register-libelle">
                        <label> Agence </label>
                        <validation-provider
                          #default="{ errors }"
                          name="montant"
                          rules=""
                        >
                          <v-select
                            v-model="agence"
                            placeholder="Selectionnez une agence"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="libelle"
                            :state="errors.length > 0 ? false : null"
                          >
                          </v-select>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-col>
                </b-row>

                <h3 class="m-2">Liste de l'article</h3>

                <div
                  v-for="(item, index) in duplicate"
                  :key="index"
                  class="bg-light p-1 mt-1"
                  style="border-radius: 10px"
                >
                  <div class="mb-1 d-flex justify-content-between border-bottom p-50">
                    <span>Detail de produit #{{ index + 1 }}</span>
                    <div
                      class="bg-white p-0 d-flex align-items-center justify-content-center cursor-pointer"
                      style="border-radius: 100%; width: 25px; height: 25px"
                    >
                      <feather-icon icon="XIcon" @click="removeItem(index)" />
                    </div>
                  </div>

                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex">
                      <b-row class="flex-grow-1">
                        <!-- Single Item Form Headers -->

                        <b-col cols="12" lg="6">
                          <label class=""
                            >Article <span class="text-danger h6">*</span></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="article"
                            rules="required"
                          >
                            <v-select
                              v-model="item.article"
                              @input="validateArticle(index)"
                              placeholder="Selectionnez un article"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="libelle"
                              :options="articleList"
                              class="bg-white"
                              :state="errors.length > 0 ? false : null"
                            >
                            </v-select>
                            <small
                              :class="valideArticle ? 'block' : 'none'"
                              class="text-danger"
                            >
                              Vous devez choisir un article'
                            </small>
                            <small
                              :class="
                                validateDuplicate && qIndex === index ? 'block' : 'none'
                              "
                              class="text-danger"
                            >
                              Vous avez déjà choisir ce article'
                            </small>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" lg="6">
                          <label class=""
                            >Quantité restante
                            <span class="p-0 text-danger h6">*</span></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Quantite restante"
                            rules="required"
                          >
                            <b-form-input
                              v-model="item.qte"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              class="mb-2"
                            />
                            <!-- <small
                            :class="valideQte? 'block' : 'none'"
                            class="text-danger"
                          >
                            Vous devez renseigner la quantité restante'
                          </small> -->
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
                <!-- The button to add new article -->
                <b-col cols="12" class="mt-1">
                  <b-button
                    id="btnadd"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="md"
                    variant="primary"
                    @click="addNewItemInItemForm"
                  >
                    Ajouter un article
                  </b-button>
                </b-col>
              </section>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <!-- 


   -->
    <b-col cols="12" md="3" xl="4">
      <b-card no-body class="d-flex py-1">
        <!-- Form Actions -->
        <b-col>
          <b-button
            class="d-flex w-100 mb-50 justify-content-center"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            @click="saveInventaire"
            :disabled="loading === true ? true : false"
          >
            <div v-if="loading === true" class="spinner-border text-primary"></div>
            <span v-else>Ajouter</span>
          </b-button>

          <b-button
            class="d-flex w-100 justify-content-center"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="retour"
          >
            Annuler
          </b-button>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";
import accountService from "@/services/account.services";
import { toast_destory, toast_orign, toast_sucess } from "@/utils/qToast";
import moment from "moment";
// import $ from "jquery";

export default {
  components: {
    flatPickr,
    jQuery,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    moment,
    BForm,
    BLink,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BFormCheckboxGroup,
    required,
    email,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    axios,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      loading: false,
      showForm: false,
      qIndex: null,
      //variable validation
      agence: "",
      libelle: "",
      valideLibelle: false,
      validateDuplicate: false,
      date: moment(new Date().toString()).format("YYYY-MM-DD"),
      valideDate: false,

      articleList: [],

      item: {
        date: "",
        montant: "",
        compte: "",
      },

      valideQte: false,
      valideArticle: false,

      duplicate: [{ article: "", qte: "" }],
      modelDuplicate: { article: "", qte: "" },
    };
  },

  setup() {
    // FUNCTION TO CONVERT NUMBER INTO CURRENCY
    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };
    return {
      formatter,
    };
  },
  async mounted() {
    document.title = "Inventaire - Ediqia";

    try {
      this.initTrHeight();
      await axios
        .get(URL.ARTICLE_LIST)
        .then((response) => {
          this.articleList = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("ok");
      console.log(error);
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    // FUNCTION DUPLICATE
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.duplicate.push(JSON.parse(JSON.stringify(this.modelDuplicate)));
      console.log(this.duplicate);
    },
    removeItem(index) {
      if (this.duplicate.length === 0) {
        //  this.showForm === false;
      }

      this.duplicate.splice(index, 1);
    },
    updateItemForm(index, val) {
      const { date } = val;
      this.duplicate[index].article = article;
    },
    // updateItemMontant(index, val) {
    //   const { montant } = val;
    //   this.duplicate[index].montant = montant;
    // },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    //validation

    validateLibelle() {
      if (!this.libelle) {
        this.valideLibelle = true;
      } else {
        this.valideLibelle = false;
      }
    },

    validateDate() {
      if (!this.date) {
        this.valideDate = true;
      } else {
        this.valideDate = false;
      }
    },

    validateArticle(index) {
      //  if (!this.duplicate[0].article) {
      //    this.valideArticle = true
      // }else  {
      //   this.valideArticle = false
      // }
      this.qIndex = index;
      const verify = this.duplicate.filter((item) => {
        return item.article.id === this.duplicate[index].article.id;
      });

      if (verify.length > 1) {
        this.validateDuplicate = true;
      } else {
        this.validateDuplicate = false;
      }
    },
    // validateQte(){
    // if (!this.duplicate[0].qte) {
    //   this.valideQte = true
    // } else {
    //   this.valideQte = false
    // }
    // },

    topEndD() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Inventaire enregistré avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    retour() {
      this.$router.push("/inventaire");
    },

    async saveInventaire() {
      console.log(this.duplicate);
      try {
        this.validateDate();
        this.validateLibelle();

        if (!this.duplicate[0].article) {
          return this.$swal({
            title: "Veuillez ajouter un article",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__shakeX",
            },
            buttonsStyling: false,
          });
        } else if (!this.duplicate[0].qte) {
          return this.$swal({
            title: "Veuillez ajouter une qantité",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__shakeX",
            },
            buttonsStyling: false,
          });
        }
        // this.validateArticle();
        ////////////////////////
        if (
          !this.libelle ||
          !this.date ||
          !this.duplicate[0].qte ||
          !this.duplicate[0].article ||
          this.validateDuplicate
        ) {
          this.loading = false;
          bvModalEvt.preventDefault();
        } else {
          this.marche = true;

          const data = {
            libelle: this.libelle,
            count: this.duplicate.length,
            item: this.duplicate,
          };
          console.log("data", data);
          const config = {
            headers: {
              Accept: "application/json",
            },
          };

          this.loading = true;
          await axios.post(URL.INVENTAIRE_CREATE, data, config).then((response) => {
            this.dataReturn = response.data;
            this.loading = false;
            toast_sucess(this, "success", "top-rigth", "Inventaire");
            this.$router.push("/inventaire");
          });

          this.marche = false;
        }
      } catch (error) {
        this.loading = false;
        // toast_destory(this, 'danger', 'top-right', 'Emprunt')
        console.log(error.type);
        this.error_global = true;

        setTimeout(() => {
          this.marche = false;
        }, 300);
      }
    },

    topEnd() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Emprunt enregistrée avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}
.none {
  display: none;
}
.block {
  display: inline-block;
}
</style>
